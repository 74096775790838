var API_BASE = 'https://responsive.traghettiper.it';

function loadScript(url, callback) {
  var head = document.getElementsByTagName('head')[0];
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.onreadystatechange = callback;
  script.onload = callback;
  head.appendChild(script);
}

function resizeIFrame() {
  iFrameResize({checkOrigin: false, heightCalculationMethod: 'taggedElement', minHeight: 600}, '#traghettiper');

  window.addEventListener('message', function(e) {
    if (e.origin !== API_BASE) return null;

    var tag = '[TraghettiPer]';
    if ((typeof e.data === 'string' || e.data instanceof String) && e.data.indexOf(tag) >= 0) {
      var message = e.data.substring(e.data.indexOf(tag) + tag.length);
      var data = JSON.parse(message);

      try {
        if (data.subject === 'container') {
          var action = data.action;
          if (action === 'replace' || action === 'assign') {
            window.location[action](data.url);
          } else if (action === 'reload') {
            window.location.reload();
          }
        }
      } catch (e) {
        console.log('Messaggio non interpretabile: ' + e);
      }
    }
  }, false);
}

// Funzione per ottenere i parametri dalla query string
function getQueryParams(url) {
  var params = {};
  var queryString = url.split('?')[1]; // Ottiene la query string dall'URL

  if (queryString) {
    var pairs = queryString.split('&');
    pairs.forEach(function(pair) {
      var keyValue = pair.split('=');
      var key = keyValue[0];
      var value = keyValue[1];
      if (key && value) {
        params[decodeURIComponent(key)] = decodeURIComponent(value);
      }
    });
  }
  return params;
}

function fetchCreditsAndAppend(params) {
  var endpoint = API_BASE + '/js/iframe-credits'; // Sostituisci con il tuo endpoint
  var xhr = new XMLHttpRequest();
  xhr.open('GET', endpoint + '?marea=' + params.marea + '&affiliate=' + params.affiliate + '&portal=' + params.portal + '&locale=' + params.locale, true);

  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4 && xhr.status === 200) {
      var responseText = xhr.responseText;
      var iframe = document.querySelector('#traghettiper');
      if (iframe && responseText) {
        var div = document.createElement('div');
        div.innerHTML = responseText;
        iframe.parentNode.insertBefore(div, iframe.nextSibling); // Aggiunge la risposta sotto l'iframe
      }
    }
  };
  xhr.send();
}

function init() {
  var iframe = document.querySelector('#traghettiper');
  if (iframe) {
    var url = iframe.src || iframe.getAttribute('data-src');
    var params = getQueryParams(url);

    if (params.affiliate && params.portal && params.locale) {
      fetchCreditsAndAppend(params);
    }
  }
}

// Esegui lo script al caricamento della pagina
document.addEventListener('DOMContentLoaded', init);

loadScript(API_BASE + '/js/iframeResizer.js', resizeIFrame);